const locale = {
  contactInfoDialog:
    'For any questions, please contact the Customer Service Desk, Monday through Friday from 9:00 a.m. to 6:30 p.m. Phone +56 22 3939001 or email: ',
  errorDialog: 'An error occurred. Please try again.',
  successDialog: 'Successful registration request submission.',
  successInfoDialog: 'Modification registered successfully',
  successInfoDialogAnnulment: 'Contract(s) annulled',
  requestSentSuccessfully: 'Request sent successfully',
  infoUpdatedSuccessfully: 'Information successfully updated',
  requestEnteredSuccessfully: 'Request successfully submitted',
  validationDialog:
    'We are validating the information, soon you will be able to see the status of the request in ',
  reports: 'Reports',
  successInfoTextAnnulment:
    'The annulment request for {{count}} contract(s) described above has been successfully recorded.',
  confirmationDialog: 'Are you sure you want to proceed?',
  confirmationDialogModify: 'Confirm modification',
  confirmationDialogAnnulment: 'Confirm cancellation',
  confirmRequest: 'Confirm request',
  confirmContractRegistration:
    'Are you sure you want to register the contract?',
  confirmationDialogParticipants:
    'Are you sure you want to create the relation?',
  confirmationDialogWarning: 'This action cannot be undone.',
  forwardContractDuplicateRequestSent:
    'The duplicate request for the forward contract has been sent successfully',
  forwardContractModifyRequestSent: 'Modification registered successfully',
  confirmSubscription: 'Confirm subscription',
  confirmSubscriptionRequest: 'Confirm subscription request',
  confirmAdvance: 'Confirm advance',
  confirmModification: 'Confirm modification',
  confirmCancellation: 'Confirm cancellation',
  confirmRejection: 'Confirm rejection',
  confirmAdvanceSubscription: 'Confirm advance subscription',
  confirmAdvanceRequest: 'Confirm advance request',
  confirmModificationSubscription: 'Confirm modification subscription',
  confirmModificationRequest: 'Confirm modification request',
  confirmAnnulmentSubscription: 'Confirm annulment subscription',
  confirmAnnulmentRequest: 'Confirm annulment request',
  confirmModAuthorization: 'Confirm modification authorization',
  confirmAuthorization: 'Confirm authorization',
  confirmRegistration: 'Confirm registration',
  confirmCanAuthorization: 'Confirm cancellation authorization',
  confirmSubAuthorization: 'Confirm subscription authorization',
  confirmButton: 'Yes, proceed',
  cancelButton: 'No, go back',
  accept: 'Yes, accept',
  noCancel: 'No, cancel',
  confirm: 'Confirm',
  understood: 'Understood',
  continue: 'Continue',
  back: 'Back',
  send: 'Send',
  retryButton: 'Try again',
  acceptButton: 'Accept',
  enterCode: 'Enter the security code',
  checkCode: 'Check the code that appears on your cell phone.',
  wrongCode: 'Wrong code',
  errorCode: 'There was an error. Please try again',
  successfulDownload: 'Successful download',
  confirmSaveChanges: 'Are you sure you want to save the changes?',
  yesSaveChanges: 'Yes, save changes',
  cancel: 'Cancel',
  confirmDeactivation: 'Are you sure you want to deactivate the contract?',
  contractPendingApprovalForDeactivation:
    'The contract is pending approval for deactivation',
  expirationDateModifiedToToday:
    'The expiration date has been modified to today',
  activeOrPendingContractExistsBetween:
    'An active or pending contract exists between: {{code1}} and {{code2}}.',
  alreadySubscribedToContracts:
    'You have already subscribed to one or more contracts from the selection',
  removeContractsFromListToContinue:
    'Remove the contract(s) from the list to continue with the subscription process:',
  contractsSubscribed: 'You have subscribed to the contract(s)',
  checkProcessStatusLater: 'Check the process status later',
  notModifiedTitle: 'No changes have been made.',
  notModifiedText: 'Please review and modify if necessary to continue',
  contractsCouldNotBeSubscribed:
    'The following contracts could not be subscribed:',
  contractsCouldNotBeAnnulled: 'The following contracts could not be annulled:',
  anticipateSuccessfullyRegistered: 'Anticipate successfully registered',
  advanceRequestSuccessfullyRegistered:
    'Advance request successfully registered',
  modificationRequestSuccess: 'Modification request successfully registered',
  advanceSubscriptionSuccessfullyRegistered:
    'Advance subscription successfully registered',
  authorizationSuccessfullyRegistered: 'Authorization successfully registered',
  modSubSuccess: 'Modification subscription successfully registered',
  modAuthSuccess: 'Modification authorization successfully registered',
  canAuthSuccess: 'Cancellation authorization successfully registered',
  annulmentSubscriptionSuccess:
    'Annulment subscription successfully registered',
  requestCancelled: 'Request cancelled',
  requestRejectedSuccessfully: 'Request rejected successfully',
  show: 'Show',
  hide: 'Hide',
  fillAllFieldsError:
    "Please complete the 'Contract ID' field in all records before proceeding.",
  fieldsMissingDetail: "The following 'Contract ID' fields are empty:",
  retry: 'Retry',
};

export default locale;
