import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import { ContactSupportOutlined } from '@mui/icons-material';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid #1A39DA',
  borderRadius: '50%',
  padding: '6px',
  color: '#1A39DA',
  backgroundColor: 'transparent',
  transition: 'background-color 0.3s, color 0.3s',
  '&:hover': {
    backgroundColor: '#8137F2',
    border: '1px solid #8137F2',
    color: 'white',
  },
  '@media (max-width: 959px)': {
    padding: '5px',
  },
}));

const QuestionIconButton = ({ t }) => {
  return (
    <Tooltip title={t('help')} arrow>
      <StyledIconButton className="hidden md:flex">
        <ContactSupportOutlined style={{ color: 'inherit' }} />
      </StyledIconButton>
    </Tooltip>
  );
};
export { QuestionIconButton };
