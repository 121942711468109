const locale = {
  notification:
    'El contrato <strong>{{contractId}}</strong> tiene una nueva solicitud de <strong>{{requestType}}</strong>',
  viewAll: 'Ver Todo',
  advance: 'Anticipo',
  cancellation: 'Anulación',
  authorization: 'Autorización',
  modification: 'Modificación',
  registration: 'Registro',
  subscription: 'Suscripción',
  multipleMessages: 'mensajes (varios)',
  notifications: 'Notificaciones',
};

export default locale;
