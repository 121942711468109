import IconButton from '@mui/material/IconButton';
import { useAppDispatch } from 'app/store/store';
import {
  selectFuseCurrentSettings,
  setDefaultSettings,
} from '@fuse/core/FuseSettings/store/fuseSettingsSlice';
import _ from '@lodash';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { FuseSettingsConfigType } from '@fuse/core/FuseSettings/FuseSettings';
import { useSelector } from 'react-redux';
import { navbarToggle, navbarToggleMobile } from './store/navbarSlice';
import React, { CSSProperties } from 'react';

type NavbarToggleButtonProps = {
  className?: string;
  children?: React.ReactNode;
  isCollapsed?: boolean;
};

/**
 * The navbar toggle button.
 */
function NavbarToggleButton(props: NavbarToggleButtonProps) {
  const {
    className = '',
    children = (
      <FuseSvgIcon size={25} color="action" style={{ color: '#1a39da' }}>
        heroicons-outline:chevron-right
      </FuseSvgIcon>
    ),
    isCollapsed = false,
  } = props;

  const dispatch = useAppDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const settings: FuseSettingsConfigType = useSelector(
    selectFuseCurrentSettings,
  );
  const { config } = settings.layout;
  const collapsedStyle = {
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s ease',
  };

  const styledChildren =
    React.isValidElement(children) &&
    typeof children.props === 'object' &&
    'style' in children.props
      ? React.cloneElement(children as React.ReactElement<any>, {
          style: isCollapsed
            ? { ...(children.props.style as CSSProperties), ...collapsedStyle }
            : children.props.style,
        })
      : children;

  return (
    <IconButton
      className={className}
      color="inherit"
      size="small"
      onClick={() => {
        if (isMobile) {
          dispatch(navbarToggleMobile());
        } else if (config?.navbar?.style === 'style-2') {
          dispatch(
            setDefaultSettings(
              _.set(
                {},
                'layout.config.navbar.folded',
                !settings?.layout?.config?.navbar?.folded,
              ),
            ),
          );
        } else {
          dispatch(navbarToggle());
        }
      }}
    >
      {styledChildren}
    </IconButton>
  );
}

export default NavbarToggleButton;
