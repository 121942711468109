const locale = {
  TITLE: 'Registrar por formulario',
  home: 'Inicio',
  duplicate: 'Duplicar',
  formRegistration: 'Registro por formulario',
  enterInformation: 'Ingresar información, campo obligatorio',
  validateInformation: 'Validar información',
  pageDescription:
    'Es un hecho bien establecido que un lector se distraerá con el contenido legible de una página.',
  identificationInformation: 'Información de identificación',
  contractId: 'Id de contrato',
  contractIdPlaceholder: 'Ingresar la información, campo obligatorio',
  contractDate: 'Fecha de negociación',
  originator: 'Originador',
  ownerContract: 'Dueño de contrato',
  selectOption: 'Seleccionar una opción',
  selectOptionRequiredField: 'Seleccionar una opción, campo obligatorio',
  investor: 'Mandante dueño del contrato',
  investorPlaceholder: 'Buscar por código o nombre',
  counterpartyInvestor: 'Mandante de contraparte',
  counterparty: 'Contraparte',
  counterpartyRUT: 'RUT de la contraparte',
  businessInformation: 'Información de negocio',
  operationType: 'Tipo de operación',
  exchangeRatesType: 'Tipo de cambio o índice referencial',
  compensationCurrency: 'Moneda de compensación',
  forwardPrice: 'Precio forward',
  paymentDay: 'Día de pago',
  subscriptionMaximumTerm: 'Fecha máxima de suscripción',
  transactionType: 'Tipo de transacción',
  complianceModality: 'Modalidad de cumplimiento',
  contractedAmount: 'Monto contado o nocional',
  agreedPrice: 'Precio pactado',
  paymentForm: 'Forma de pago',
  expirationDate: 'Fecha de vencimiento',
  back: 'Volver',
  continue: 'Continuar',
  submitApplication: 'Enviar solicitud',
  mandatoryField: 'Campo obligatorio',
  requiredField: 'Campo requerido',
  alphaNumericError: 'En este campo solo puedes ingresar números y letras',
  exceededIntegerDigits: 'Límite de enteros superado.',
  exceededDecimalDigits: 'Límite de decimales superado.',
  invalidCharacter: 'Carácter inválido.',
  nonPositiveValue: 'El valor debe ser positivo.',
  zeroValueNotAllowed: 'No se permite el valor cero.',
  completeAllMandatory:
    'Para duplicar el contrato se deben completar todos los campos obligatorios',
  completeAllMandatoryFieldsToModify:
    'Para modificar el contrato se deben completar todos los campos obligatorios',
  dateinputYear: 'aaaa',
  'calendar.today': 'Hoy',
  'dropdowns.nodata': 'Datos no encontrados.',
  modify: 'Guardar',
  overallStatus: 'Estado general',
  operationStatus: 'Estado de la operación',
  statusReason: 'Razón de estado',
  dcvFolio: 'Folio DCV',
  idCounterpartyContract: 'Id. contrato contraparte',
  apiErrorMessage:
    'Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
  'daterangepicker.cancel': 'Cancelar',
  'daterangepicker.set': 'Seleccionar',
};

export default locale;
