import { AuthRole } from '../auth/authRoles';
import { hasPermission } from '../shared-components/utils/permissions';

const navHome = {
  id: 'sidebar.home',
  title: 'home',
  type: 'item',
  class: 'homeSection',
  url: '/home',
};
const navRelationParticipant = {
  id: 'sidebar.relationparticipant',
  title: 'frameworkContracts',
  type: 'item',
  url: '/relation-participant',
};
const navApprovalRepresentatives = {
  id: 'sidebar.approvalrepresentatives',
  title: 'participants',
  type: 'item',
  url: '/representative-approval',
};
const navRegister = {
  id: 'sidebar.register',
  title: 'register',
  type: 'collapse',
  children: [
    {
      id: 'sidebar.register.documentregister',
      title: 'document_register',
      type: 'item',
      icon: 'heroicons-outline:file-register',
      url: '/file-register',
      disabled: 'registerFile',
    },
    {
      id: 'sidebar.register.formregister',
      title: 'form_register',
      type: 'item',
      icon: 'heroicons-outline:document-register',
      url: '/form-register',
      disabled: 'registerGui',
    },
  ],
};
const navSearch = {
  id: 'sidebar.search',
  title: 'search',
  type: 'item',
  url: '/search',
};
const navSeeApplication = {
  id: 'sidebar.seeaplication',
  title: 'see_aplication',
  type: 'item',
  url: '/counterparty-requests',
  disabled: 'viewCounterpartyRequests',
};
const navReports = {
  id: 'sidebar.reports',
  title: 'reports',
  type: 'item',
  url: '/reports',
  disabled: 'exportAutomaticReports',
};

function updateDisabledField(menuItems: any[]): any[] {
  return menuItems.map((item) => {
    const updatedItem = {
      ...item,
      disabled: item.disabled ? !hasPermission(item.disabled) : false,
    };

    if (item.children && item.children.length > 0) {
      updatedItem.children = updateDisabledField(item.children);
    }

    return updatedItem;
  });
}

export function resolveRoleNavigation(roles: string[]) {
  const normalizedRoles = roles.map((role) => role.replace(/\s/g, ''));

  const combinedNavigations = normalizedRoles.reduce((accumulatedNav, role) => {
    const roleNavigation = strippedNavigationByRole[role];

    if (!roleNavigation) {
      return accumulatedNav;
    }

    roleNavigation.forEach((navItem) => {
      if (
        !accumulatedNav.some((existingItem) => existingItem.id === navItem.id)
      ) {
        accumulatedNav.push(navItem);
      }
    });

    return accumulatedNav;
  }, []);

  return updateDisabledField(combinedNavigations);
}

const navigationByRole = {
  [AuthRole.R035IE]: [
    navHome,
    navRegister,
    navSearch,
    navSeeApplication,
    navReports,
    navRelationParticipant,
  ],
  [AuthRole.R064E]: [
    navHome,
    navRegister,
    navSearch,
    navSeeApplication,
    navReports,
    navRelationParticipant,
  ],
  [AuthRole.R065E]: [
    navHome,
    navRegister,
    navSearch,
    navSeeApplication,
    navReports,
    navRelationParticipant,
  ],
  [AuthRole.R066E]: [
    navHome,
    navRegister,
    navSearch,
    navSeeApplication,
    navReports,
    navRelationParticipant,
  ],
  [AuthRole.R067E]: [
    navHome,
    navRegister,
    navSearch,
    navSeeApplication,
    navReports,
    navRelationParticipant,
  ],
  [AuthRole.R068I]: [
    navHome,
    navApprovalRepresentatives,
    navRelationParticipant,
    navSearch,
    navReports,
  ],
  [AuthRole.R069I]: [
    navHome,
    navApprovalRepresentatives,
    navRelationParticipant,
    navSearch,
    navReports,
  ],
};

const strippedNavigationByRole = Object.fromEntries(
  Object.entries(navigationByRole).map(([key, value]) => {
    return [key.replace(/\s/g, ''), value];
  }),
);

export default navigationByRole;
