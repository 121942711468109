import { AxiosResponse } from 'axios';
import apiNuamClient from '../../clients/nuamApiClient';
import { getCurrentUser } from '../../shared-components/utils/userUtils';

export const getNotificationCount = (
  page?: number,
  pageSize?: number,
): Promise<AxiosResponse<unknown>> => {
  const user = getCurrentUser();

  const data = {
    originator_code: user?.data?.participantCode,
    Page: 0,
    PageSize: 0,
    Order: '',
  };

  return apiNuamClient
    .post('notification/getNotificationCount', data)
    .then((response) => response.data);
};

export const getNotificationRegister = (
  page: number,
  pageSize: number,
): Promise<AxiosResponse<unknown>> => {
  const user = getCurrentUser();

  const data = {
    originator_code: user?.data?.participantCode,
    Page: 0,
    PageSize: 0,
    Order: '',
  };

  return apiNuamClient
    .post('notification/getNotificationRegister', data)
    .then((response) => response.data);
};

export const getNotificationModify = (
  page: number,
  pageSize: number,
): Promise<AxiosResponse<unknown>> => {
  const user = getCurrentUser();

  const data = {
    originator_code: user?.data?.participantCode,
    Page: 0,
    PageSize: 0,
    Order: '',
  };

  return apiNuamClient
    .post('notification/getNotificationModify', data)
    .then((response) => response.data);
};

export const getNotificationAdvance = (
  page: number,
  pageSize: number,
): Promise<AxiosResponse<unknown>> => {
  const user = getCurrentUser();

  const data = {
    originator_code: user?.data?.participantCode,
    Page: 0,
    PageSize: 0,
    Order: '',
  };

  return apiNuamClient
    .post('notification/getNotificationAdvance', data)
    .then((response) => response.data);
};

export const getNotificationAnnulment = (
  page: number,
  pageSize: number,
): Promise<AxiosResponse<unknown>> => {
  const user = getCurrentUser();

  const data = {
    originator_code: user?.data?.participantCode,
    Page: 0,
    PageSize: 0,
    Order: '',
  };

  return apiNuamClient
    .post('notification/getNotificationAnnulment', data)
    .then((response) => response.data);
};

export const getNotificationAuth = (
  page: number,
  pageSize: number,
): Promise<AxiosResponse<unknown>> => {
  const user = getCurrentUser();

  const data = {
    originator_code: user?.data?.participantCode,
    Page: 0,
    PageSize: 0,
    Order: '',
  };

  return apiNuamClient
    .post('notification/getNotificationAuth', data)
    .then((response) => response.data);
};

export const getNotificationSus = (
  page: number,
  pageSize: number,
): Promise<AxiosResponse<unknown>> => {
  const user = getCurrentUser();

  const data = {
    originator_code: user?.data?.participantCode,
    Page: 0,
    PageSize: 0,
    Order: '',
  };

  return apiNuamClient
    .post('notification/getNotificationSus', data)
    .then((response) => response.data);
};
