const locale = {
  TITLE: 'Anticipate',
  home: 'Home',
  search: 'Search',
  viewContract: 'View contract',
  requests: 'Requests',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  back: 'Back',
  contractAdvanceData: 'Contract advance data',
  compensatingParty: 'Compensating party',
  advanceDate: 'Advance date',
  advancePaymentDate: 'Advance payment date',
  advancePaymentForm: 'Advance payment form',
  complianceModality: 'Advance compliance modality',
  selectOption: 'Select an option',
  selectOptionRequiredField: 'Select an option, required field',
  enterInformation: 'Enter information',
  enterInformationRequiredField: 'Enter information, required field',
  mandatoryField: 'Mandatory field',
  paymentCurrency: 'Advance payment currency',
  amountToCompensate: 'Amount to compensate',
  advancePaymentAmount: 'Advance payment amount',
  paymentDate: 'Payment date',
  paymentForm: 'Payment form',
  anticipate: 'Anticipate',
  confirm: 'Confirm',
  subscribeAdvance: 'Subscribe advance',
  authorizeAdvance: 'Authorize advance',
  apiErrorMessage: 'An error has occurred. Please try again later.',
};

export default locale;
