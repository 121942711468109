const locale = {
  TITLE: 'Registrar por documento',
  home: 'Inicio',
  fileRegistration: 'Registro por documento',
  pageDescription:
    'Es un hecho bien establecido que un lector se distraerá con el contenido legible de una página.',
  uploadTitle: 'clic para seleccionar',
  dragTitle: 'Arrastre aquí su archivo o haga',
  uploadDescriptionFormat: 'Documento formato CSV',
  uploadDescriptionMaximumSize: 'Tamaño máximo 1MB',
  continue: 'Continuar',
  errorMessage:
    'Ocurrió un error. Intenta de nuevo o puedes contactarnos llamando a (562) 2393 9001 y escribiendo a ',
  csvFormatError: 'El documento debe estar en formato CSV.',
  fileSizeError: 'El tamaño del archivo es demasiado grande, supera 1MB.',
  differentCsvFileError: 'Intente con un archivo CSV diferente.',
  emptyFileError: 'Archivo vacío. Seleccione un archivo con datos.',
  invalidFileError: 'Archivo inválido',
  emptyContentError: 'Archivo sin contenido válido.',
  fileReadError: 'Error al leer el archivo.',
  downloadTemplate: 'Descargar formato',
  remove: 'Eliminar',
};

export default locale;
