const locale = {
  home: 'Inicio',
  search: 'Buscar',
  requests: 'Solicitudes',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  contract: 'Contrato',
  viewContract: 'Ver contrato',
  back: 'Volver',
  register: 'Registrar',
  authorizeRegistration: 'Autorizar registro',
  'grid.noRecords': 'No hay registros disponibles.',
  apiErrorMessage:
    'Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
};

export default locale;
