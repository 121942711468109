export const AuthRole = {
  R035IE: '035 I/E',
  R064E: '064 E',
  R065E: '065 E',
  R066E: '066 E',
  R067E: '067 E',
  R068I: '068 I',
  R069I: '069 I',
  RGUEST: [] as string[],
} as const;

export type AuthRoleType = (typeof AuthRole)[keyof typeof AuthRole];
