import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { memo } from 'react';
import Navigation from 'app/theme-layouts/shared-components/navigation/Navigation';
import NavbarToggleButton from 'app/theme-layouts/shared-components/navbar/NavbarToggleButton';
import Logo from '../../../../shared-components/Logo';
import UserNavbarHeader from '../../../../shared-components/UserNavbarHeader';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: '#1a39da',
  width: 70, // Ancho reducido para el navbar minimizado
  '& ::-webkit-scrollbar-thumb': {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.24)'
        : 'rgba(255, 255, 255, 0.24)'
    }`,
  },
  '& ::-webkit-scrollbar-thumb:active': {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.37)'
        : 'rgba(255, 255, 255, 0.37)'
    }`,
  },
  '& .photo': {
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
  },
}));

const StyledContent = styled(FuseScrollbars)(() => ({
  overscrollBehavior: 'contain',
  overflowX: 'hidden',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 10px',
  backgroundAttachment: 'local, scroll',
  color: 'blue',
}));

type NavbarStyle3ContentMinimizedProps = {
  className?: string;
};
function NavbarStyle3ContentMinimized(
  props: NavbarStyle3ContentMinimizedProps,
) {
  const { className = '' } = props;

  return (
    <Root
      className={clsx(
        'flex h-full flex-auto flex-col overflow-hidden w-full',
        className,
      )}
    >
      {/* <div className="flex h-48 shrink-0 flex-row items-center px-20">
        <Logo className="min-w-0" />
      </div> */}

      <StyledContent
        className="flex min-h-0 flex-1 flex-col items-center"
        option={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <div className="photo">
          <UserNavbarHeader isCollapsed={true} />
        </div>
        <Navigation layout="vertical" />
      </StyledContent>
    </Root>
  );
}

export default memo(NavbarStyle3ContentMinimized);
