const locale = {
  TITLE: 'View contract',
  home: 'Home',
  search: 'Search',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  back: 'Back',
  contractId: 'Contract Id',
  LEGAL_DATA: 'Legal data',
  REQUESTS: 'Requests',
  MY_REQUESTS: 'My requests',
  COUNTERPARTY_REQUESTS: 'Counterparty requests',
  HISTORY: 'History',
  selectOption: 'Select option',
  print: 'Print',
  download: 'Download',
  cancel: 'Cancel',
  reject: 'Reject',
  void: 'Void',
  advance: 'Advance',
  duplicate: 'Duplicate',
  modify: 'Modify',
  authorize: 'Authorize',
  subscribe: 'Subscribe',
  seeMore: 'See more',
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  requestStatus: 'Request status',
  registrationDate: 'Registration date',
  requestType: 'Request type',
  apiErrorMessage: 'An error has occurred. Please try again later.',
  noInformationToSee: 'There isn’t any information to display',
  contractRegistration: 'Contract registration',
  contractModification: 'Contract modification',
  contractCancellation: 'Contract cancellation',
  contractAdvance: 'Contract advance',
  contractSubscription: 'Contract subscription',
  preparingPages: 'Preparing {{numLoadedPages}}/{{numPages}} pages...',
  date: 'Date',
  type: 'Type',
  action: 'Action',
  user: 'User',
  contractOrRequestStatus: 'Contract/Request status',
  reasonForStatus: 'Reason for status',
  invalidParameters: 'Invalid parameters',
};

export default locale;
