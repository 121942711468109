const locale = {
  TITLE: 'Buscar',
  home: 'Inicio',
  title_table: 'Resultados de contrato',
  select_option: 'Seleccionar opción',
  more_filters: 'Más filtros',
  counterparty_code: 'Código contraparte',
  counterparty_name: 'Nombre contraparte',
  counterparty: 'Contraparte',
  contract_owner: 'Dueño del contrato',
  state_operation: 'Estado de la operación',
  date_expiration_from: 'Fecha de vencimiento desde',
  date_expiration_to: 'Fecha de vencimiento hasta',
  id_contract: 'Id contrato',
  waiting_signature: 'Pendientes de mi firma',
  type_operation: 'Tipo de operación',
  date_contract_from: 'Fecha de contrato registrado desde',
  date_contract_to: 'Fecha de contrato registrado hasta',
  date_format: 'dd/mm/aaaa',
  dcv_id: 'Folio DCV',
  principal: 'Mandante (asociados al participante)',
  secondary: 'Mandante de la contraparte',
  transaction_type: 'Tipo de transacción',
  see_contract: 'Ver contrato(s)',
  export_to_csv: 'Exportar a CSV',
  searchResults: 'resultado-búsqueda',
  authorize: 'Autorizar',
  duplicate: 'Duplicar',
  subscribe: 'Suscribir',
  request: 'Solicitar',
  file_box: 'Archivo a casilla',
  anticipate: 'Anticipar',
  contractInformation: 'Información del contrato',
  contractAdvanceData: 'Datos anticipo del contrato',
  noInformationToSee: 'No hay información para mostrar',
  folio_grid: 'Folio',
  id_contract_grid: 'Id de contrato',
  contract_owner_grid_code: 'Código dueño de contrato',
  contract_owner_grid_name: 'Nombre dueño de contrato',
  date_exp_grid: 'Fecha de vencimiento',
  state_grid: 'Estado',
  nocional_amount: 'Monto contratado o nocional',
  pacted_price_grid: 'Precio pactado',
  forward_price_grid: 'Precio forward',
  reason_state_grid: 'Razón de estado',
  transit_state_grid: 'Estado de tránsito',
  owner_principal_grid_code: 'Código mandante dueño de contrato',
  owner_principal_grid_name: 'Nombre mandante dueño de contrato',
  owner_secondary_grid_code: 'Código mandante contraparte',
  owner_secondary_grid_name: 'Nombre mandante contraparte',
  register_date_grid: 'Fecha de registro',
  tryAnotherSearch: 'Intenta nueva búsqueda',
  ERROR_NOTIFICATION:
    'Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
  onlyOneContractCanBeDuplicatedAtATime:
    'Solo puedes duplicar un contrato a la vez',
  contractsFailRequirements:
    'Uno o más contratos de la selección no cumple con los requisitos para ser suscrito',
  contractsFailRequirementsAnnulment:
    'Uno o más contratos de la selección no cumple con los requisitos para ser anulado',
  contractsFailRequirementsAdvance:
    'El contrato seleccionado no cumple con los requisitos para ser anticipado',
  contractsFailRequirementsModify:
    'El contrato seleccionado no cumple con los requisitos para ser modificado',
  contractOwnerDataFetchError:
    'Error al obtener los datos de dueño de contrato',
  due_date: 'Fecha de vencimiento',
  no_options_available: 'No hay opciones disponibles',
  modify: 'Modificar',
  annulment: 'Anular',
  requestIsBeingProcessed: 'La solicitud se está procesando',
};

export default locale;
