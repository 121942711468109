const locale = {
  TITLE: 'Modify',
  home: 'Home',
  search: 'Search',
  viewContract: 'View contract',
  requests: 'Requests',
  back: 'Back',
  modify: 'Modify',
  initialContract: 'Initial contract',
  modifiedContract: 'Modified contract',
  modifiedFields: 'Modified fields',
  reject: 'Reject',
  accept: 'Accept',
  confirm: 'Confirm',
  subscribeModify: 'Subscribe modification',
  authorizeModification: 'Authorize modification',
  apiErrorMessage: 'An error has occurred. Please try again later.',
  errorSignatureCheck: 'Error verifying the contract signature.',
};

export default locale;
