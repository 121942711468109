const locale = {
  TITLE: 'Anticipar',
  home: 'Inicio',
  search: 'Buscar',
  viewContract: 'Ver contrato',
  requests: 'Solicitudes',
  pageDescription:
    'Es un hecho bien establecido que un lector se distraerá con el contenido legible de una página.',
  back: 'Volver',
  contractAdvanceData: 'Datos anticipo del contrato',
  compensatingParty: 'Parte que compensa',
  advanceDate: 'Fecha de anticipo',
  advancePaymentDate: 'Fecha de pago de anticipo',
  advancePaymentForm: 'Forma de pago de anticipo',
  complianceModality: 'Modalidad de cumplimiento del anticipo',
  selectOption: 'Seleccionar una opción',
  selectOptionRequiredField: 'Seleccionar una opción, campo obligatorio',
  enterInformation: 'Ingresar información',
  enterInformationRequiredField: 'Ingresar información, campo obligatorio',
  mandatoryField: 'Campo obligatorio',
  paymentCurrency: 'Moneda de pago de anticipo',
  amountToCompensate: 'Valor a compensar',
  advancePaymentAmount: 'Monto a pagar del anticipo',
  paymentDate: 'Fecha de pago',
  paymentForm: 'Forma de pago',
  anticipate: 'Anticipar',
  confirm: 'Confirmar',
  subscribeAdvance: 'Suscribir anticipo',
  authorizeAdvance: 'Autorizar anticipo',
  apiErrorMessage:
    'Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
};

export default locale;
