import Hidden from '@mui/material/Hidden';
import { Theme } from '@mui/system/createTheme';
import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useSelector } from 'react-redux';
import { selectFuseCurrentLayoutConfig } from '@fuse/core/FuseSettings/store/fuseSettingsSlice';
import { useAppDispatch } from 'app/store/store';
import { Layout1ConfigDefaultsType } from 'app/theme-layouts/layout1/Layout1Config';
import {
  navbarCloseMobile,
  selectFuseNavbar,
} from 'app/theme-layouts/shared-components/navbar/store/navbarSlice';
import NavbarStyle3Content from './NavbarStyle3Content';
import NavbarStyle3ContentMinimized from './NavbarStyle3ContentMinimized';

const navbarWidth = 290;

type StyledNavBarProps = {
  theme?: Theme;
  open: boolean;
  position: string;
};

const StyledNavBar = styled('div')<StyledNavBarProps>(
  ({ theme, open, position }) => ({
    ...(!open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
      minWidth: navbarWidth / 2,
      width: navbarWidth / 2,
      maxWidth: navbarWidth / 2,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      minWidth: navbarWidth,
      width: navbarWidth,
      maxWidth: navbarWidth,
    }),
    transition: 'all 0.5s',
  }),
);

const StyledNavBarMobile = styled(SwipeableDrawer)(() => ({
  '& .MuiDrawer-paper': {
    minWidth: navbarWidth,
    width: navbarWidth,
    maxWidth: navbarWidth,
  },
}));

/**
 * The navbar style 1.
 */
function NavbarStyle3() {
  const dispatch = useAppDispatch();
  const config = useSelector(
    selectFuseCurrentLayoutConfig,
  ) as Layout1ConfigDefaultsType;
  const navbar = useSelector(selectFuseNavbar);
  return (
    <>
      <Hidden lgDown>
        <StyledNavBar
          className="sticky top-0 z-20 h-screen flex-auto shrink-0 flex-col overflow-hidden shadow"
          open={navbar.open}
          position={config.navbar.position}
        >
          {navbar.open ? (
            <NavbarStyle3Content />
          ) : (
            <NavbarStyle3ContentMinimized />
          )}
        </StyledNavBar>
      </Hidden>

      <Hidden lgUp>
        <StyledNavBarMobile
          classes={{
            paper: 'flex-col flex-auto h-full',
          }}
          anchor={config.navbar.position as 'left' | 'top' | 'right' | 'bottom'}
          variant="temporary"
          open={navbar.mobileOpen}
          onClose={() => dispatch(navbarCloseMobile())}
          onOpen={() => {}}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavbarStyle3Content />
        </StyledNavBarMobile>
      </Hidden>
    </>
  );
}

export default NavbarStyle3;
