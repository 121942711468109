const locale = {
  TITLE: 'Solicitudes pendientes',
  home: 'Inicio',
  homeTitle: 'Información del participante',
  participants: 'Participantes',
  ASSETS: 'Activos',
  DISABLED: 'Desactivados',
  PENDING: 'Pendiente',
  addNewRepresentative: 'Crear apoderado',
  back: 'Volver',
  rutTitle: 'RUT',
  representativeNameTitle: 'Nombre apoderado',
  signaturePlaceTitle: 'Lugar de firma',
  notaryTitle: 'Notario',
  writingDateTitle: 'Fecha de escritura',
  actionsTitle: 'Acciones',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  approve: 'Aprobar',
  reject: 'Rechazar',
  deactivate: 'Desactivar',
  approveDeact: 'Aprobar desactivación',
  rejectDeact: 'Rechazar desactivación',
  noInformationToSee: 'No hay información para mostrar',
  messageConfirmation: 'Confirmar solicitud',
  successInfoDialogPrimaryMessage:
    'El apoderado quedó en estado pendiente de desactivación',
  approveMessage: 'Solicitud aprobada con éxito',
  rejectMessage: 'Solicitud rechazada con éxito',
  ALERT_NOTIFICATION: 'No puedes aprobar o rechazar tus propias solicitudes.',
  config_title: 'Configuración supervisión solicitada:',
  config_subtitle: 'Habilitar autorización de:',
  Anticipate: 'Anticipo',
  Annulment: 'Anulación',
  Modify: 'Modificación',
  Register: 'Registro',
  Subscribe: 'Suscripción',
  save: 'Guardar',
  confirm: 'Confirmar',
  companyConfigDataFetchError:
    'Error al obtener los datos de configuración de la empresa',
  requestSuccessfullyProcessed: 'Solicitud gestionada con éxito',
  errorConfirmingRequest: 'Error al confirmar la solicitud',
  errorConfirmingRequestTryAgain:
    'Error al confirmar la solicitud. Por favor, inténtalo de nuevo.',
  errorRejectingRequest: 'Error al rechazar la solicitud',
  errorRejectingRequestTryAgain:
    'Error al rechazar la solicitud. Por favor, inténtalo de nuevo.',
  errorSavingConfig: 'Error al guardar la configuración',
  errorSavingConfigTryAgain:
    'Error al guardar la configuración. Por favor, inténtalo de nuevo.',
};
export default locale;
