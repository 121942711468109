import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { alpha, styled } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import {
  ListItemButton,
  ListItemButtonProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import FuseNavBadge from '../../FuseNavBadge';
import FuseSvgIcon from '../../../FuseSvgIcon';
import { FuseNavItemComponentProps } from '../../FuseNavItem';
import { useSelector, useDispatch } from 'react-redux';
import {
  navbarCloseMobile,
  selectFuseNavbar,
} from 'app/theme-layouts/shared-components/navbar/store/navbarSlice';
import { useTranslation } from 'react-i18next';

type ListItemButtonStyleProps = ListItemButtonProps & {
  itempadding: number;
};

const Root = styled(ListItemButton)<ListItemButtonStyleProps>(
  ({ theme, ...props }) => ({
    minHeight: 44,
    width: '100%',
    borderRadius: '6px',
    margin: '0 0 4px 0',
    color: alpha(theme.palette.text.primary, 0.7),
    cursor: 'pointer',
    textDecoration: 'none!important',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '&.active': {
      color: theme.palette.text.primary,
      backgroundColor:
        theme.palette.mode === 'light'
          ? 'rgba(0, 0, 0, .05)!important'
          : 'rgba(255, 255, 255, .1)!important',
      pointerEvents: 'none',
      transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
      '& > .fuse-list-item-text-primary': {
        color: 'inherit',
      },
      '& > .fuse-list-item-icon': {
        color: 'inherit',
      },
    },
    '& >.fuse-list-item-icon': {
      marginRight: 0,
      color: 'inherit',
      height: '20px',
      minWidth: '20px',
      minHeight: '20px',
    },
    '& > .fuse-list-item-text': {
      display: 'flex',
    },
    '& span.fuse-list-item-subtitle': {
      fontSize: '12px',
    },
    '& .rounded-icon': {
      width: '50px',
      height: '50px',
      minWidth: '50px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#e0e0e0',
      fontSize: '24px',
      fontWeight: '700',
      cursor: 'pointer',
    },
  }),
);

/**
 * FuseNavVerticalItem is a React component used to render FuseNavItem as part of the Fuse navigational component.
 */
function FuseNavVerticalItem(props: FuseNavItemComponentProps) {
  const { t } = useTranslation('navigation');
  const dispatch = useDispatch();
  const { item, nestedLevel = 0, onItemClick, checkPermission } = props;

  const itempadding = nestedLevel > 0 ? 38 + nestedLevel * 16 : 16;

  const component = item.url ? NavLinkAdapter : 'li';

  let itemProps = {};

  const navbar = useSelector(selectFuseNavbar);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  if (typeof component !== 'string') {
    itemProps = {
      disabled: item.disabled,
      to: item.url || '',
      end: item.end,
      role: 'button',
    };
  }

  if (checkPermission && !item?.hasPermission) {
    return null;
  }
  let classOfComponent = 'fuse-list-item-title';
  let classOfAElement = '';
  let classOfChildren = '';
  if (nestedLevel > 0) {
    classOfComponent = `fuse-list-item-subtitle !pb-[1px] !pt-[1px] !p-0 !justify-center ${!navbar.open && !navbar.mobileOpen ? 'h-[42px] !ps-0 max-w-[50px]' : '!ps-[5px]'} `;
    classOfChildren = 'fuse-list-item-children !ps-[15px] !pe-[20px]';
    classOfAElement = 'fuse-a-item-children';
  }
  const title =
    !navbar.open && !navbar.mobileOpen && nestedLevel > 0 ? '' : item.title;
  const textAlign =
    !navbar.open && !navbar.mobileOpen ? 'text-center round-icon' : '';
  const rounded =
    !navbar.open && !navbar.mobileOpen && nestedLevel === 0
      ? 'rounded-icon'
      : '';
  const classOfText =
    !navbar.open && !navbar.mobileOpen && nestedLevel > 0 ? 'hidden' : '';
  const classOfIcon =
    !navbar.open && !navbar.mobileOpen && nestedLevel > 0 ? 'w-full' : '';

  useEffect(() => {
    if (isLargeScreen && navbar.mobileOpen) {
      dispatch(navbarCloseMobile());
    }
  }, [isLargeScreen, navbar.mobileOpen, dispatch]);

  return useMemo(
    () => (
      <div className={classOfChildren}>
        <Root
          component={component}
          className={clsx('fuse-list-item', classOfComponent, classOfAElement)}
          onClick={() => onItemClick && onItemClick(item)}
          itempadding={itempadding}
          sx={item.sx}
          {...itemProps}
        >
          {item.icon && (
            <FuseSvgIcon
              className={clsx(
                'fuse-list-item-icon shrink-0',
                item.iconClass,
                classOfIcon,
              )}
              color="action"
            >
              {item.icon}
            </FuseSvgIcon>
          )}
          <ListItemText
            className={clsx('fuse-list-item-text', textAlign)}
            primary={navbar.open || navbar.mobileOpen ? t(title) : t(title)[0]}
            secondary={item.subtitle}
            classes={{
              primary: `text-13 font-medium fuse-list-item-text-primary priest truncate ${classOfComponent} ${rounded} ${classOfText}`,
              secondary:
                'text-11 font-medium fuse-list-item-text-secondary leading-normal truncate',
            }}
          />
          {item.badge && <FuseNavBadge badge={item.badge} />}
        </Root>
      </div>
    ),
    [item, itempadding, onItemClick, navbar],
  );
}

const NavVerticalItem = FuseNavVerticalItem;

export default NavVerticalItem;
