const locale = {
  notification:
    'The contract <strong>{{contractId}}</strong> has a new <strong>{{requestType}}</strong> request',
  viewAll: 'View All',
  advance: 'Advance',
  cancellation: 'Cancellation',
  authorization: 'Authorization',
  modification: 'Modification',
  registration: 'Registration',
  subscription: 'Subscription',
  multipleMessages: 'multiple messages',
  notifications: 'Notifications',
};

export default locale;
