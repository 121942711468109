import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';
import { AuthRole } from 'src/app/auth/authRoles';

i18next.addResourceBundle('en', 'fileRegisterPage', en);
i18next.addResourceBundle('es', 'fileRegisterPage', es);

const FileRegister = lazy(() => import('./FileRegisterPage'));

const FileRegisterConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'file-register',
      element: <FileRegister />,
    },
  ],
  auth: [AuthRole.R066E],
};

export default FileRegisterConfig;
