import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  changeLanguage,
  LanguageType,
  selectCurrentLanguage,
  selectLanguages,
} from 'app/store/i18nSlice';
import { useAppDispatch } from 'app/store/store';
import esFlag from './assets/images/flags/es.svg';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup } from '@mui/material';
import i18next from 'i18next';
import en from './top-menu/i18n/en';
import es from './top-menu/i18n/es';
import { useTranslation } from 'react-i18next';

i18next.addResourceBundle('en', 'configuration', en);
i18next.addResourceBundle('es', 'configuration', es);

/**
 * The language switcher.
 */
function LanguageSwitcher() {
  let localLanguage = localStorage.getItem('language');
  localLanguage = localLanguage != null ? localLanguage : 'es';
  const [isSpanish, setIsSpanish] = useState<boolean>(false);
  const [isEnglish, setIsEnglish] = useState<boolean>(false);
  const [languageType, setLanguageType] = useState<string>(localLanguage);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('configuration');

  useEffect(() => {
    switch (localLanguage) {
      case 'es':
        setIsSpanish(true);
        return;
      case 'en':
        setIsEnglish(true);
        return;
    }
  }, []);

  const handleSpanishChange = () => {
    if (isSpanish) {
      return;
    }
    setIsSpanish(true);
    setIsEnglish(false);
    setLanguageType('es');
    localStorage.setItem('language', 'es');
    handleChangeLanguage('es');
  };

  const handleEnglishChange = () => {
    if (isEnglish) {
      return;
    }
    setIsEnglish(true);
    setIsSpanish(false);
    setLanguageType('en');
    localStorage.setItem('language', 'en');
    handleChangeLanguage('en');
  };

  const handleChangeLanguage = (lang: string) => {
    dispatch(changeLanguage(lang));
  };

  return (
    <>
      <div>
        <div className="p-20">
          <span className="font-bold pb-10">{t('Language')}</span>
          <div className="pl-10 pr-10 pt-10">
            <div className="flex items-center">
              <Checkbox onChange={handleSpanishChange} checked={isSpanish} />
              <span>{t('Spanish')}</span>
            </div>
            <div className="flex items-center">
              <Checkbox onChange={handleEnglishChange} checked={isEnglish} />
              <span>{t('English')}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LanguageSwitcher;
