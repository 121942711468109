import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache, { Options } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import { selectMainTheme } from '@fuse/core/FuseSettings/store/fuseSettingsSlice';

import { load } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import numbers from 'cldr-numbers-full/main/es/numbers.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';
import KendoDialog from './shared-components/kendo-dialog/KendoDialog';
import appConfig from './appConfig';
import { AuthRouteProvider } from './auth/AuthRouteProvider';
import withAppProviders from './withAppProviders';

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames,
);

/**
 * Axios HTTP Request defaults
 */
axios.defaults.baseURL = appConfig.apiUrl;

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

/**
 * The main App component.
 */
function App() {
  /**
   * The language direction from the Redux store.
   */
  const langDirection = useSelector(selectCurrentLanguageDirection);

  /**
   * The main theme from the Redux store.
   */
  const mainTheme = useSelector(selectMainTheme);

  return (
    <CacheProvider
      value={createCache(emotionCacheOptions[langDirection] as Options)}
    >
      <FuseTheme theme={mainTheme} direction={langDirection}>
        <AuthRouteProvider>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            classes={{
              containerRoot:
                'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
            }}
          >
            <KendoDialog />
            <FuseLayout layouts={themeLayouts} />
          </SnackbarProvider>
        </AuthRouteProvider>
      </FuseTheme>
    </CacheProvider>
  );
}

export default withAppProviders(App);
