const locale = {
  TITLE: 'Relation among the participants',
  home: 'Home',
  search: 'Search',
  viewContract: 'View contract',
  requests: 'Requests',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  back: 'Back',
  contractId: 'Contract Id',
  contract: 'Contract',
  registryDate: 'Trading date',
  originatorCode: 'Originator code',
  originatorName: 'Originator name',
  ownerContractCode: 'Owner contract code',
  ownerContractName: 'Owner contract name',
  investor: 'Contract owner investor',
  counterpartyCode: 'Counterparty code',
  counterpartyName: 'Counterparty name',
  counterpartyRUT: 'Counterparty RUT',
  investorCode: 'Contract owner investor code',
  investorName: 'Contract owner investor name',
  counterpartyInvestorCode: 'Counterparty investor code',
  counterpartyInvestorName: 'Counterparty investor name',
  operationType: 'Operation type',
  exchangeRateType: 'Exchange rates or index',
  clearingCurrencyCode: 'Clearing currency',
  forwardPrice: 'Forward price',
  payDayCode: 'Pay day',
  maxSubscriptionTermType: 'Max subscription term',
  stateReason: 'Reason for status',
  transactionType: 'Transaction type',
  complianceModeType: 'Compliance modality',
  notionalAmount: 'Notional amount',
  agreedPrice: 'Agreed price',
  paymentMethodType: 'Payment method',
  dueDate: 'Expiration date',
  contractDate: 'Contract date',
  status: 'Status',
  operationStatus: 'Operation status',
  statusInTransit: 'Status in transit',
  dcvFolio: 'Folio',
  afpTicker: 'AFP ticker',
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  subscribe: 'Subscribe',
  recordCount: 'Number of records:',
  contractIdPlaceholder: 'Enter the information, mandatory field',
};

export default locale;
