import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from '@progress/kendo-react-buttons';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from 'src/app/auth/AuthRouteProvider';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Typography from '@mui/material/Typography';
import Women from './assets/img/women.svg';
import DCVLogo from './assets/img/DcvLogo.svg';
import FondoMovil from './assets/img/FondoMovil.svg';
import Questions from './assets/img/Questions.svg';
import './assets/styles/Login.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import es from './i18n/es';
import en from './i18n/en';
import ArrowForwardIcon from './../../../assets/Icons/ArrowForward.svg';

i18next.addResourceBundle('es', 'sign-in', es);
i18next.addResourceBundle('en', 'sign-in', en);

const schema = z.object({
  username: z.string().min(1),
  password: z.string().min(1),
  token: z.string().min(1),
  company: z.string().min(1),
});

type FormType = {
  username?: string;
  password: string;
  token: string;
  company: string;
  remember?: boolean;
};

const defaultValues = {
  username: '',
  password: '',
  token: '',
  company: '',
  remember: true,
};

function Login() {
  const { jwtService } = useAuth();

  const { t } = useTranslation('sign-in');

  const { control, formState, handleSubmit, setValue, setError } =
    useForm<FormType>({
      mode: 'onChange',
      defaultValues,
      resolver: zodResolver(schema),
    });

  const { dirtyFields, isSubmitting } = formState;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [notification, setNotification] = useState({
    open: false,
    message: t('ERROR_NOTIFICATION'),
    severity: 'error',
  });

  const [questionsNotification, setQuestionsNotification] = useState({
    open: false,
    message: t('INFO_NOTIFICATION'),
    severity: 'info',
  });

  const [showFields, setShowFields] = useState({
    password: false,
    token: false,
  });

  const handleClickShowField = (field: string) => {
    setShowFields((prevFields) => ({
      ...prevFields,
      [field]: !prevFields[field],
    }));
  };

  const handleNotificationClose = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const handleCloseSnackbar = () => {
    setQuestionsNotification((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleExitedSnackbar = () => {
    setQuestionsNotification((prevState) => ({
      ...prevState,
      message: '',
      severity: '',
    }));
  };

  const removeSpacesOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void,
  ) => {
    const valueWithoutSpaces = event.target.value.replace(/\s+/g, '');
    onChange(valueWithoutSpaces);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth, FondoMovil]);

  async function onSubmit(formData: FormType) {
    const { username, password, token, company } = formData;
    await jwtService
      .signIn({
        username,
        password,
        token,
        company,
        grant_type: 'password',
        client_id: 'dcv-isv',
        scope: 'openid',
      })
      .catch(() => {
        setNotification({
          open: true,
          message: t('ERROR_NOTIFICATION'),
          severity: 'error',
        });
      });
  }
  return (
    <div className="login-container background-landing-page">
      <div className="login-content">
        <div className="questions-button-container">
          <Button
            onClick={() =>
              setQuestionsNotification({
                open: true,
                message: t('INFO_NOTIFICATION'),
                severity: 'info',
              })
            }
          >
            <img
              src={Questions}
              alt="Questions"
              className="z-20 questions-card"
            />
          </Button>
          <Snackbar
            TransitionProps={{ onExited: handleExitedSnackbar }}
            open={questionsNotification.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            className="custom-info"
          >
            <MuiAlert
              icon={false}
              elevation={6}
              variant="filled"
              onClose={handleCloseSnackbar}
              severity={questionsNotification.severity as AlertColor}
              className="custom-info-style"
            >
              {questionsNotification.message}
            </MuiAlert>
          </Snackbar>
        </div>
        <div className="container-dcv">
          <img
            src={DCVLogo}
            alt="DCV"
            className="style-img-card"
            style={{ height: '50px' }}
          />
        </div>
        <div className="grid grid-cols-2 justify-items-start w-full container-form">
          <form
            name="loginForm"
            noValidate
            className="style-form relative"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="welcome-type mt-32">
              <span>{t('WELCOME_TYPE_1')}</span>
              <span>{t('WELCOME_TYPE_2')}</span>
            </div>
            <div className="mb-6 mt-16">
              <Typography>{t('ENTER_DATA')}</Typography>
            </div>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-6"
                  autoFocus
                  type="text"
                  variant="outlined"
                  required
                  fullWidth
                  placeholder={t('USER')}
                  onChange={(e) => removeSpacesOnChange(e, field.onChange)}
                  InputProps={{
                    style: {
                      color: field.value ? 'blue' : 'inherit',
                    },
                  }}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  className="mb-6"
                  type={showFields.password ? 'text' : 'password'}
                  required
                  fullWidth
                  placeholder={t('PASSWORD')}
                  onChange={(e) => removeSpacesOnChange(e, field.onChange)}
                  inputProps={{
                    style: {
                      color: field.value ? 'blue' : 'inherit',
                    },
                  }}
                  endAdornment={
                    field.value && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowField('password')}
                          edge="end"
                          sx={{ color: '#1A39DA' }}
                        >
                          {showFields.password ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              )}
            />
            <Controller
              name="token"
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  className="mb-6"
                  type={showFields.token ? 'text' : 'password'}
                  required
                  fullWidth
                  placeholder={t('TOKEN')}
                  onChange={(e) => removeSpacesOnChange(e, field.onChange)}
                  inputProps={{
                    style: {
                      color: field.value ? 'blue' : 'inherit',
                    },
                  }}
                  endAdornment={
                    field.value && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle token visibility"
                          onClick={() => handleClickShowField('token')}
                          edge="end"
                          sx={{ color: '#1A39DA' }}
                        >
                          {showFields.token ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              )}
            />
            <Controller
              name="company"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-6"
                  variant="outlined"
                  fullWidth
                  required
                  placeholder={t('PARTICIPANT_CODE')}
                  onChange={(e) => removeSpacesOnChange(e, field.onChange)}
                  InputProps={{
                    style: {
                      color: field.value ? 'blue' : 'inherit',
                    },
                  }}
                />
              )}
            />

            <div className="kendo-buttons kendo-custom-style">
              <Button
                color="secondary"
                className="kendo-custom-button login-button mt-16 w-full"
                aria-label="Sign in"
                type="submit"
                size="large"
                disabled={!formState.isValid || isSubmitting}
              >
                {t('LOGIN')}
                <img
                  src={ArrowForwardIcon}
                  alt="Download Example"
                  className="inline-block pl-10 w-[20px] h-auto"
                />
              </Button>
            </div>
          </form>
          <div className="flex justify-end card-img">
            <img src={Women} alt="Women" className="justify-img" />
          </div>
        </div>
        <div className="error-alert">
          <Snackbar
            open={notification.open}
            autoHideDuration={6000}
            onClose={handleNotificationClose}
            sx={{
              position: 'static',
              bottom: 'initial',
              left: 'initial',
              right: 'initial',
              zIndex: 'initial',
            }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleNotificationClose}
              severity={notification.severity as AlertColor}
              className="custom-alert-style"
            >
              {notification.message}
            </MuiAlert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}

export default Login;
