import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Badge, IconButton, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import {
  fetchNotificationCount,
  selectTotalNotifications,
} from './store/notificationsSlice';
import { useAppDispatch } from '../../../store/store';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'notifications', en);
i18next.addResourceBundle('es', 'notifications', es);

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid #1A39DA',
  borderRadius: '50%',
  padding: '6px',
  color: '#1A39DA',
  backgroundColor: 'transparent',
  transition: 'background-color 0.3s, color 0.3s',
  '&:hover': {
    backgroundColor: '#8137F2',
    border: '1px solid #8137F2',
    color: 'white',
    '& .MuiBadge-badge': {
      transition: 'background-color 0.3s, color 0.3s',
      backgroundColor: 'white',
      border: '1px solid #8137F2',
      color: '#8137F2',
    },
  },
  '@media (max-width: 959px)': {
    padding: '5px',
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#1A39DA',
    color: 'white',
    fontSize: '9px',
    right: -5,
    borderRadius: '50%',
    padding: '0 4px',
    '@media (max-width: 959px)': {
      right: -6,
      top: 20,
      border: '1px solid white',
    },
  },
}));

interface NotificationBellToggleButtonProps {
  customClassName?: string;
}

const NotificationBellToggleButton: React.FC<
  NotificationBellToggleButtonProps
> = ({ customClassName }) => {
  const dispatch = useAppDispatch();
  const totalNotificationsCount = useSelector(selectTotalNotifications);
  const { t } = useTranslation('notifications');
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchNotificationCount());
  }, [dispatch]);

  const handleClick = () => {
    navigate('/notifications');
  };
  return (
    <Tooltip title={t('notifications')} arrow>
      <StyledIconButton
        color="inherit"
        className={customClassName}
        onClick={handleClick}
      >
        <StyledBadge badgeContent={totalNotificationsCount} max={99}>
          <NotificationsIcon
            style={{ color: 'inherit' }}
            className="w-[15px] md:w-[25px] h-auto"
          />
        </StyledBadge>
      </StyledIconButton>
    </Tooltip>
  );
};

export default NotificationBellToggleButton;
