const locale = {
  Greeting: 'Hola',
  help: 'Ayuda',
  settings: 'Ajustes',
  Participant: 'Participante',
  Organization: 'Banco BCI',
  SignIn: 'Iniciar sesión',
  SignUp: 'Registrarse',
  SignOut: 'Cerrar sesión',
  Settings: 'Ajuste',
  Help: 'Ayuda',
  LogOut: 'Cerrar Sesión',
  English: 'Inglés',
  Spanish: 'Español',
  Language: 'Idioma',
  HiddenSessionInfo: 'Información de sesión',
};

export default locale;
