const locale = {
  TITLE: 'Anular',
  home: 'Inicio',
  search: 'Buscar',
  annulment: 'Anular',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  contract: 'Contrato',
  viewContract: 'Ver contrato',
  requests: 'Solicitudes',
  back: 'Volver',
  authorizeCancellation: 'Autorizar anulación',
  subscribeAnnulment: 'Subscribir anulación',
  bulkAnnulmentsUnavailable:
    'No se puede procesar anulaciones masivas por el momento',
  'grid.noRecords': 'No hay registros disponibles.',
  apiErrorMessage:
    'Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
};

export default locale;
