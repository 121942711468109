import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useThemeMediaQuery } from '@fuse/hooks';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { memo } from 'react';
import Navigation from 'app/theme-layouts/shared-components/navigation/Navigation';
import NavbarToggleButton from 'app/theme-layouts/shared-components/navbar/NavbarToggleButton';
import Logo from '../../../../shared-components/Logo';
import UserNavbarHeader from '../../../../shared-components/UserNavbarHeader';
import { selectFuseNavbar } from 'app/theme-layouts/shared-components/navbar/store/navbarSlice';
import { useSelector } from 'react-redux';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: '#1a39da',
  '& ::-webkit-scrollbar-thumb': {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.24)'
        : 'rgba(255, 255, 255, 0.24)'
    }`,
  },
  '& ::-webkit-scrollbar-thumb:active': {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.37)'
        : 'rgba(255, 255, 255, 0.37)'
    }`,
  },
  '& .photo': {
    marginTop: '20px',
    marginLeft: '35px',
    marginBottom: '35px',
  },
}));

const StyledContent = styled(FuseScrollbars)(() => ({
  overscrollBehavior: 'contain',
  overflowX: 'hidden',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 10px',
  backgroundAttachment: 'local, scroll',
  color: 'blue',
}));

type NavbarStyle3ContentProps = {
  className?: string;
};

/**
 * The navbar style 3 content.
 */
function NavbarStyle3Content(props: NavbarStyle3ContentProps) {
  const { className = '' } = props;
  const navbar = useSelector(selectFuseNavbar);
  const isLargeScreen = useThemeMediaQuery((theme) =>
    theme.breakpoints.up('lg'),
  );
  return (
    <Root
      className={clsx(
        'flex h-full flex-auto flex-col overflow-hidden',
        className,
      )}
    >
      {' '}
      <div className="photo">
        <div className="flex h-48 shrink-0 flex-row items-center md:h-72">
          <div className="mx-4 flex flex-1">
            <UserNavbarHeader isCollapsed={false} />
          </div>

          {isLargeScreen ? (
            <NavbarToggleButton
              isCollapsed={navbar.open}
              className="h-40 w-40 p-0"
            />
          ) : (
            <NavbarToggleButton
              isCollapsed={navbar.open}
              className="h-40 w-40 p-0"
            >
              <FuseSvgIcon
                size={25}
                color="action"
                style={{ color: '#1a39da' }}
              >
                heroicons-outline:x
              </FuseSvgIcon>
            </NavbarToggleButton>
          )}
        </div>
      </div>
      <StyledContent
        className="flex min-h-0 flex-1 flex-col"
        option={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <Navigation layout="vertical" />
        {/*
				<div className="flex-0 flex items-center justify-center py-48 opacity-10">
					<img
						className="w-full max-w-64"
						src="assets/images/logo/logo.svg"
						alt="footer logo"
					/>
				</div>
				*/}
      </StyledContent>
    </Root>
  );
}

export default memo(NavbarStyle3Content);
