import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../store/store';
import { getNotificationCount } from '../../../../nuam/api/apiNuamNotifications';

export interface NotificationCount {
  advance: number;
  annulment: number;
  auth: number;
  modify: number;
  request: number;
  suscribe: number;
  total: number;
}

const initialState = {
  status: 'idle',
  error: null,
  notificationCounts: {
    advance: 0,
    annulment: 0,
    authorization: 0,
    modification: 0,
    registration: 0,
    subscription: 0,
    total: 0,
  },
};

export const fetchNotificationCount = createAsyncThunk(
  'notifications/fetchNotificationCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getNotificationCount();
      return {
        advance: response['advance'] || 0,
        annulment: response['annulment'] || 0,
        authorization: response['auth'] || 0,
        modification: response['modify'] || 0,
        registration: response['request'] || 0,
        subscription: response['suscribe'] || 0,
        total: response['total'] || 0,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationCount.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchNotificationCount.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.notificationCounts = action.payload;
      })
      .addCase(fetchNotificationCount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Something went wrong';
      });
  },
});
export const selectNotificationStatus = (state: RootState) =>
  state.notifications.status;
export const selectNotificationError = (state: RootState) =>
  state.notifications.error;
export const selectTotalNotifications = (state: RootState) =>
  state.notifications.notificationCounts.total;
export const selectCountsByType = (state: RootState) =>
  state.notifications.notificationCounts;

export default notificationsSlice.reducer;
