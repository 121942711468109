import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import FuseNavItem from '../FuseNavItem';
import { FuseNavigationProps } from '../FuseNavigation';
import { FuseNavItemType } from '../types/FuseNavItemType';

const StyledList = styled(List)(({ theme }) => ({
  color: '#1a39da',
  '& .fuse-list-item': {
    color: '#1a39da',
    '&:hover': {
      color: '#1a39da',
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.05)'
          : 'rgba(0,0,0,.04)',
    },
    '&.Mui-disabled': {
      '& .fuse-list-item-text-primary': {
        color: '#757575',
      },
      '& .fuse-list-item-icon, & .fuse-list-item-icon svg': {
        color: '#757575',
        fill: '#757575 !important',
      },
    },
  },
  '& .fuse-list-item-title': {
    '& .fuse-list-item-text': {
      borderBottom: '1px solid #1A39DA',
      paddingBottom: '4px',
      marginBottom: '8px',
      paddingLeft: '0',
      marginLeft: '26px',
      marginRight: '30px',
    },
    '&.active .fuse-list-item-text': {
      borderBottom: '2px solid #1A39DA',
    },
    '& .fuse-list-item-text-primary': {
      color: '#1a39da',
      fontFamily: 'Arial, sans-serif',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '34px',
      padding: '5px 0',
      position: 'relative',
      whiteSpace: 'initial',
    },
    '&.Mui-disabled .fuse-list-item-text': {
      borderBottom: '1px solid #757575',
    },
  },
  '& a.fuse-list-item-subtitle': {
    color: '#1a39da',
    padding: '4px 0',
    backgroundColor: 'transparent',
    textAlign: 'center',
    border: '1px solid #1A39DA',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: 'none !important',
    transition: 'all .3s',
    fontSize: '12px',
    minHeight: '20px',
    width: 'auto',
    paddingInline: '10px',
    '&.Mui-disabled ': {
      color: '#757575',
      border: '1px solid #757575',
    },
  },
  '&.active-square-list': {
    '& .fuse-list-item, & .active.fuse-list-item': {
      width: '100%',
      borderRadius: '0',
    },
  },
  '&.dense': {
    '& .fuse-list-item': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
    },
  },
  '& .fuse-list-item-children': {
    marginBottom: '15px',
    paddingRight: '16px',
    paddingLeft: '16px',
    marginRight: '20px',
  },
  '& .fuse-a-item-children:hover': {
    color: 'white',
    backgroundColor: '#8137F2',
    border: '1px solid #8137F2',
  },
  '& .fuse-a-item-children:hover use': {
    color: 'white',
  },
  '& .fuse-a-item-children:hover span': {
    color: 'white',
  },
  '& .fuse-a-item-children.active': {
    color: 'white',
    backgroundColor: '#1a39da !important',
  },
  '& .fuse-a-item-children.active use': {
    color: 'white',
  },
  '& .fuse-a-item-children.active span': {
    color: 'white',
  },
  '& .fuse-a-item-children:active': {
    color: 'white',
    backgroundColor: '#1a39da !important',
  },
  '& .fuse-a-item-children:active use': {
    color: 'white',
  },
  '& .fuse-a-item-children:active span': {
    color: 'white',
  },
  '& .fuse-a-item-children &:focus:not(.active) use': {
    color: '#1a39da',
  },
  '& .fuse-a-item-children &:focus:not(.active) span': {
    color: '#1a39da',
  },
}));

/**
 * FuseNavVerticalLayout1
 * This component is used to render vertical navigations using
 * the Material-UI List component. It accepts the FuseNavigationProps props
 * and renders the FuseNavItem components accordingly
 */
function FuseNavVerticalLayout1(props: FuseNavigationProps) {
  const { navigation, active, dense, className, onItemClick, checkPermission } =
    props;

  function handleItemClick(item: FuseNavItemType) {
    onItemClick?.(item);
  }

  return (
    <StyledList
      className={clsx(
        'navigation whitespace-nowrap pr-12 py-0',
        `active-${active}-list`,
        dense && 'dense',
        className,
      )}
    >
      {navigation.map((_item) => (
        <FuseNavItem
          key={_item.id}
          type={`vertical-${_item.type}`}
          item={_item}
          nestedLevel={0}
          onItemClick={handleItemClick}
          checkPermission={checkPermission}
        />
      ))}
    </StyledList>
  );
}

export default FuseNavVerticalLayout1;
