const locale = {
  home: 'Home',
  register: 'Register',
  frameworkContracts: 'Framework contracts',
  see_aplication: 'View counterparty request',
  reports: 'Reports',
  search: 'Search',
  document_register: 'Register by document',
  form_register: 'Register by form',
  participants: 'Participants',
};

export default locale;
