const locale = {
  home: 'Inicio',
  register: 'Registrar',
  frameworkContracts: 'Contratos marco',
  see_aplication: 'Ver solicitudes contraparte',
  reports: 'Reportes',
  search: 'Buscar',
  document_register: 'Registrar por documento',
  form_register: 'Registrar por formulario',
  participants: 'Participantes',
};

export default locale;
