import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';
import { AuthRole } from 'src/app/auth/authRoles';

i18next.addResourceBundle('en', 'participantPage', en);
i18next.addResourceBundle('es', 'participantPage', es);

const Participant = lazy(() => import('./ParticipantPage'));

const ParticipantConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'relation-participant',
      element: <Participant />,
    },
  ],
  auth: [
    AuthRole.R064E,
    AuthRole.R065E,
    AuthRole.R066E,
    AuthRole.R067E,
    AuthRole.R068I,
    AuthRole.R069I,
  ],
};

export default ParticipantConfig;
