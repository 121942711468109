const locale = {
  TITLE: 'Messages',
  back: 'Back',
  view: 'View',
  refresh: 'Refresh',
  requestMessage:
    'You have a new request between the contract owner: <strong>{{ownerContract}}</strong> and the counterparty: <strong>{{counterparty}}</strong>.',
  message:
    'You have a new request for the contract with Id: <strong>{{contractId}}</strong> and contract owner: <strong>{{ownerContract}}</strong>.',
  notificationTitle:
    'You have <strong>{{count}}</strong> <strong>{{requestType}}</strong> {{requestTypeCount}}',
  advance: 'Advance',
  annulment: 'Annulment',
  authorization: 'Authorization',
  modification: 'Modification',
  registration: 'Registration',
  subscription: 'Subscription',
  multipleMessages: 'multiple messages',
  request: 'request',
  requests: 'requests',
  noInformationToSee: `There isn't any information to display`,
  apiErrorMessage: 'An error has occurred. Please try again later.',
};

export default locale;
