import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/app/auth/user/store/userSlice';
import DCV from './assets/dcv.svg';
import DcvMovil from './assets/dcvMovil.svg';
import './assets/styles/UserNavbarHeader.css';
import NavbarToggleButton from './navbar/NavbarToggleButton';
import { selectFuseNavbar } from './navbar/store/navbarSlice';
import clsx from 'clsx';

const Root = styled('div')(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function UserNavbarHeader({ isCollapsed }) {
  const user = useSelector(selectUser);
  const navbar = useSelector(selectFuseNavbar);
  const classNavbarMinimized =
    !navbar.open && isCollapsed != false ? 'pl-[55px]' : '';
  return (
    <Root className="user relative flex flex-col items-center justify-center w-full shadow-0">
      <div className="mb-24 mt-16 flex items-center w-full justify-center">
        <div className="flex w-full justify-start">
          <img
            className={clsx(classNavbarMinimized)}
            src={!navbar.open && isCollapsed != false ? DcvMovil : DCV}
          />
        </div>
        {!navbar.open && isCollapsed != false && (
          <>
            <NavbarToggleButton className="h-40 w-40 p-0" />
          </>
        )}
      </div>
    </Root>
  );
}

export default UserNavbarHeader;
