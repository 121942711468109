import i18next from 'i18next';
import en from './top-menu/i18n/en';
import es from './top-menu/i18n/es';
import { useTranslation } from 'react-i18next';
import { Switch } from '@mui/material';
import { useState } from 'react';

i18next.addResourceBundle('en', 'configuration', en);
i18next.addResourceBundle('es', 'configuration', es);

function HideSessionInformation() {
  let is_hidden = JSON.parse(localStorage.getItem('is_hidden'));
  is_hidden = is_hidden != null ? is_hidden : true;
  const { t } = useTranslation('configuration');
  const [isHidden, setIsHidden] = useState<boolean>(is_hidden);

  const handleChangeSessionInformation = () => {
    setIsHidden(!is_hidden);
    localStorage.setItem('is_hidden', (!is_hidden).toString());
    applyStyleToElement();
  };

  const applyStyleToElement = () => {
    const element = document.querySelector('.info-container');
    (element as HTMLElement).style.visibility = !isHidden
      ? 'visible'
      : 'hidden';
  };

  return (
    <>
      <div>
        <div className="ps-10 pe-10 pt-20">
          <div
            className="flex items-center ps-10 pe-10 pb-20"
            style={{ borderBottom: '1px solid #E0E0E0' }}
          >
            <div className="w-full">
              <span className="font-bold">{t('HiddenSessionInfo')}</span>
            </div>
            <div className="w-full text-end">
              <Switch
                onChange={handleChangeSessionInformation}
                checked={isHidden}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HideSessionInformation;
