const locale = {
  TITLE: 'Mensajes',
  back: 'Volver',
  view: 'Ver',
  refresh: 'Actualizar',
  requestMessage:
    'Tienes una nueva solicitud entre el dueño del contrato: <strong>{{ownerContract}}</strong> y la contraparte: <strong>{{counterparty}}</strong>.',
  message:
    'Tienes una nueva solicitud para el contrato con Id: <strong>{{contractId}}</strong> y dueño de contrato: <strong>{{ownerContract}}</strong>.',
  notificationTitle:
    'Tienes <strong>{{count}}</strong> {{requestTypeCount}} de <strong>{{requestType}}</strong>',
  advance: 'Anticipo',
  annulment: 'Anulación',
  authorization: 'Autorización',
  modification: 'Modificación',
  registration: 'Registro',
  subscription: 'Suscripción',
  multipleMessages: 'mensajes (varios)',
  request: 'solicitud',
  requests: 'solicitudes',
  noInformationToSee: 'No hay información para mostrar',
  apiErrorMessage:
    'Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.',
};

export default locale;
