const locale = {
  TITLE: 'Register by form',
  home: 'Home',
  duplicate: 'Duplicate',
  formRegistration: 'Form registration',
  enterInformation: 'Enter information, mandatory field',
  validateInformation: 'Validate information',
  pageDescription:
    'It is a long established fact that a reader will be distracted by the readable content of a page.',
  identificationInformation: 'Identification information',
  contractId: 'Contract Id',
  contractIdPlaceholder: 'Enter the information, mandatory field',
  contractDate: 'Trading date',
  originator: 'Originator',
  ownerContract: 'Contract owner',
  selectOption: 'Select an option',
  selectOptionRequiredField: 'Select an option, required field',
  investor: 'Contract owner investor',
  investorPlaceholder: 'Search by code or name',
  counterpartyInvestor: 'Counterparty investor',
  counterparty: 'Counterparty',
  counterpartyRUT: 'Counterparty RUT',
  businessInformation: 'Business information',
  operationType: 'Operation type',
  exchangeRatesType: 'Exchange rates or index',
  compensationCurrency: 'Compensation currency',
  forwardPrice: 'Forward price',
  paymentDay: 'Payment day',
  subscriptionMaximumTerm: 'Subscription deadline',
  transactionType: 'Transaction type',
  complianceModality: 'Compliance modality',
  contractedAmount: 'Notional amount',
  agreedPrice: 'Agreed price',
  paymentForm: 'Payment form',
  expirationDate: 'Expiration date',
  back: 'Back',
  continue: 'Continue',
  submitApplication: 'Submit application',
  mandatoryField: 'Mandatory field',
  requiredField: 'Required field',
  alphaNumericError: 'In this field, you can only enter numbers and letters.',
  exceededIntegerDigits: 'Integer limit exceeded.',
  exceededDecimalDigits: 'Decimal limit exceeded.',
  invalidCharacter: 'Invalid character.',
  nonPositiveValue: 'The value must be positive.',
  zeroValueNotAllowed: 'Zero value is not allowed.',
  completeAllMandatory:
    'To duplicate the contract, all mandatory fields must be filled',
  completeAllMandatoryFieldsToModify:
    'To modify the contract, all mandatory fields must be filled',
  dateinputYear: 'yyyy',
  'calendar.today': 'Today',
  'dropdowns.nodata': 'No data found.',
  modify: 'Save',
  overallStatus: 'Overall status',
  operationStatus: 'Operation status',
  statusReason: 'Status reason',
  dcvFolio: 'DCV folio',
  idCounterpartyContract: 'Counterparty contract Id',
  apiErrorMessage: 'An error has occurred. Please try again later.',
  'daterangepicker.cancel': 'Cancel',
  'daterangepicker.set': 'Select',
};

export default locale;
