const locale = {
  TITLE: 'Pending Requests',
  home: 'Home',
  homeTitle: 'Participant information',
  participants: 'Participants',
  ASSETS: 'Assets',
  DISABLED: 'Disabled',
  PENDING: 'Pending',
  addNewRepresentative: 'Create Representative',
  back: 'Back',
  rutTitle: 'RUT',
  representativeNameTitle: 'Representative Name',
  signaturePlaceTitle: 'Signature Place',
  notaryTitle: 'Notary',
  writingDateTitle: 'Writing Date',
  actionsTitle: 'Actions',
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  approve: 'Approve',
  reject: 'Reject',
  deactivate: 'Deactivate',
  approveDeact: 'Approve deactivation',
  rejectDeact: 'Reject deactivation',
  noInformationToSee: 'There isn’t any information to display',
  messageConfirmation: 'Confirm request',
  successInfoDialogPrimaryMessage: 'The representative is pending deactivation',
  approveMessage: 'Request successfully approved',
  rejectMessage: 'Request successfully rejected',
  ALERT_NOTIFICATION: 'You cannot approve or reject your own requests.',
  config_title: 'Requested Supervision Configuration:',
  config_subtitle: 'Enable Authorization for:',
  Anticipate: 'Advance',
  Annulment: 'Annulment',
  Modify: 'Modification',
  Register: 'Registration',
  Subscribe: 'Subscription',
  save: 'Save',
  confirm: 'Confirm',
  companyConfigDataFetchError: 'Error fetching company configuration data',
  requestSuccessfullyProcessed: 'Request successfully processed',
  errorConfirmingRequest: 'Error confirming the request',
  errorConfirmingRequestTryAgain:
    'Error confirming the request. Please try again.',
  errorRejectingRequest: 'Error rejecting the request',
  errorRejectingRequestTryAgain:
    'Error rejecting the request. Please try again.',
  errorSavingConfig: 'Error saving the configuration',
  errorSavingConfigTryAgain:
    'Error saving the configuration. Please try again.',
};

export default locale;
