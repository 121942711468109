const locale = {
  back: 'Back',
  home: 'Home',
  title: 'Framework contracts',
  information: 'Information',
  contractId: 'Contract Id',
  lastUpdateDate: 'Last update date',
  contractStatus: 'Contract status',
  active: 'Active',
  inactive: 'Inactive',
  pending: 'Pending',
  pendingDeactivation: 'Pending deactivation',
  generalConditionsDate: 'Date of general conditions',
  dateOfRecord: 'Record date',
  placeOfCompliance: 'Place of fulfillment',
  referenceBanks: 'Reference banks',
  referenceBank1: 'Business name 1',
  referenceBank2: 'Business name 2',
  referenceBank3: 'Business name 3',
  participant1: 'Participant 1',
  participantCode: 'Participant code',
  businessName: 'Legal entity name',
  participantRUT: 'Participant RUT',
  code: 'Code',
  RUT: 'RUT',
  principals: 'Principals',
  add: 'Add',
  register: 'Register',
  cancel: 'Cancel',
  save: 'Save',
  approve: 'Approve',
  reject: 'Reject',
  remove: 'Remove',
  participant2: 'Participant 2',
  enterInformation: 'Enter information',
  selectOption: 'Select an option',
  mandatoryField: 'Required field',
  requiredField: 'Required field',
  completeAllMandatory: 'To register, all mandatory fields must be completed',
  edit: 'Edit',
  deactivate: 'Deactivate',
  newRecord: 'New record',
  searchTool: 'Search tool',
  searchByCodeOrBusinessName: 'Search by code or business name',
  principalAlreadyAdded: 'Principal already added',
  errorFetchingParticipantData: 'Error fetching participant data',
  alphaNumericError: 'In this field, you can only enter numbers and letters',
  invalidCharacter: 'Invalid character',
  requestPendingApproval: 'The request is pending approval',
};

export default locale;
